export const FILTER_PLANS = "FILTER_PLANS";
//todo: JEsus este debe moverse a accountState //renombrar: setPlanList
// export const setSubscriptionSubType = (
//   subscriptionSubTypes: SubscriptionSubType[]
// ) => ({
//   type: SET_SUBSCRIPTIONSUBTYPE,
//   subscriptionSubTypes,
// });

//todo: JEsus este me parece redundante con el de arriba
// export const updateSubscriptionSubType = () => ({
//   type: UPDATE_SUBSCRIPTIONSUBTYPE,
// });

//todo: JEsus este esta OK//renombrar: filterPlanByCountry
export const filterPlanByCountry = (value: string) => ({
  type: FILTER_PLANS,
  value,
});

//todo: JEsus este debe moverse a accountState//renombrar: setSelectedPlan
// export const setPlan = (plan: SubscriptionSubType | undefined) => ({
//   type: SET_PLAN,
//   plan,
// });
