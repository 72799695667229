import {
  setStep1Response,
  setStep2Response,
} from "./../../store/account/Actions";
import store from "../../store";
import { setProcessingRequest } from "../../store/account/Actions";
import httpClient from "../AxiosWrapper";
import {
  CardRequest,
  LeadRequest,
  UtmRequest,
  NewAccountStep1Request,
  NewAccountStep2Request,
  NewAccountStep1Response,
  NewAccountStep2Response,
  PlanRequest,
} from "./Types";

//step 1
export const buildStep1Request = () => {
  const state = store.getState().accountState;

  let Lead: LeadRequest = {
    ...state.Lead,
    CountryCode: state.selectedCountryCode,
    BillingId: state.Billing.BillingId,
  };

  const billing: CardRequest = {
    ExpirationYear: state.Billing.ExpirationYear,
    ExpirationMonth: state.Billing.ExpirationMonth,
    CVV: state.Billing.CVV,
    CardHolder: state.Billing.FullName,
    CardNumber: state.Billing.CreditCardNumber,
  };

  const plan: PlanRequest = {
    PlanId: state.SelectedPlan.PlanId,
    PlanSubTypeId: state.SelectedPlan.PlanSubTypeId,
    Pricing: state.SelectedPlan.Pricing,
    IsTry: state.SelectedPlan.IsTry,
    EnableNutrition: state.SelectedPlan.UseNutrition,
    EnableOdontology: state.SelectedPlan.UseOdontology,
    EnableBilling: state.SelectedPlan.UseBilling,
  };

  const Utm: UtmRequest = { ...state.Utm };

  const resquest: NewAccountStep1Request = {
    Lead: Lead,
    Utm: Utm,
    Card: billing,
    Plan: plan,
  };
  return resquest;
};

export const createAccountStep1 = async (request: NewAccountStep1Request) => {
  store.dispatch(setProcessingRequest(true));

  const url = process.env.REACT_APP_COMMON_API_URL + "/Api/Account/V1/Account";

  const response = await httpClient
    .post<NewAccountStep1Response>(url, request)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.response) {
        const errorResponse: NewAccountStep1Response = {
          HasError: true,
          Error: `Error at request ${err.response.status}:${err.response.data} `,
          SuccessMessage: null,
          Value: null,
        };
        return errorResponse;
      } else {
        const unknownErrorResponse: NewAccountStep1Response = {
          HasError: true,
          Error: `Unexpected error, please try again`,
          SuccessMessage: null,
          Value: null,
        };
        return unknownErrorResponse;
      }
    });

  store.dispatch(setProcessingRequest(false));
  store.dispatch(setStep1Response(response));

  return response;
};

//step 2
export const buildStep2Request = () => {
  const state = store.getState().accountState;

  let Lead: LeadRequest = {
    ...state.Lead,
    CountryCode: state.selectedCountryCode,
    BillingId: state.Billing.BillingId,
  };

  const plan: PlanRequest = {
    PlanId: state.SelectedPlan.PlanId,
    PlanSubTypeId: state.SelectedPlan.PlanSubTypeId,
    Pricing: state.SelectedPlan.Pricing,
    IsTry: state.SelectedPlan.IsTry,
    EnableNutrition: state.SelectedPlan.UseNutrition,
    EnableOdontology: state.SelectedPlan.UseOdontology,
    EnableBilling: state.SelectedPlan.UseBilling,
  };

  const resquest: NewAccountStep2Request = {
    Lead: Lead,
    Plan: plan,
  };
  return resquest;
};

export const createAccountStep2 = async (
  request: NewAccountStep2Request,
  id: string
) => {
  store.dispatch(setProcessingRequest(true));

  const url =
    process.env.REACT_APP_COMMON_API_URL +
    "/Api/Account/V1/Account/" +
    id +
    "/step2";

  const response = await httpClient
    .put<NewAccountStep2Response>(url, request)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.response) {
        const errorResponse: NewAccountStep2Response = {
          HasError: true,
          Error: `Error at request ${err.response.status}:${err.response.data} `,
          SuccessMessage: null,
          Value: null,
        };
        return errorResponse;
      } else {
        const unknownErrorResponse: NewAccountStep2Response = {
          HasError: true,
          Error: `Unexpected error, please try again`,
          SuccessMessage: null,
          Value: null,
        };
        return unknownErrorResponse;
      }
    });

  store.dispatch(setProcessingRequest(false));
  store.dispatch(setStep2Response(response));

  return response;
};
