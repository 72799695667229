import React from "react";
import { Layout } from "antd";
import WhatsAppSignup from "../../components/WhatsApp/WhatsAppSignup";
import { logAmplitudeEvent } from "../../utils/amplitude";

const WhatsApp: React.FC = () => {
  const { Content } = Layout;

  logAmplitudeEvent("NAV_WHATSAPPPAGE");

  return (
    <>
      <Content className="site-layout">
        <h1 className="title">Configuración WhatsApp bussiness</h1>
        <div className="site-layout-content" style={{ background: "#fff" }}>
          <WhatsAppSignup />
        </div>
      </Content>
    </> 
  );
};

export default WhatsApp;
