import React, { useEffect } from "react";
import { Trans } from "@lingui/react";
import { getSpecialtyList } from "../../services/SpecialtyService";
import "@ant-design/compatible/assets/index.css";
import {
  Row,
  Col,
  Select,
  Button,
  Input,
  Progress,
  Form,
  InputNumber,
} from "antd";
import {
  LeadState,
  AccountState,
  setLeadInformation,
  setPasswordStatus,
} from "../../store/account";
import { useSelector, useDispatch } from "react-redux";
import { SignupState, showStepOne } from "../../store/signup";
import "./LeadInformation.css";
const LeadInformation: React.FC = () => {
  const [form] = Form.useForm();
  const { accountState } = useSelector(mapStateToProps);
  const {
    Lead,
    isProcessingRequest,
    passwordLevel,
    passwordErrors,
    passwordPercentage,
    passwordColor,
  } = accountState;
  const dispatch = useDispatch();

  useEffect(() => {
    form.setFieldsValue({
      FirstName: Lead.FirstName,
      LastName: Lead.LastName,
      selectedCountryCode: accountState.selectedCountryCode,
      Specialty: Lead.Specialty,
      PhoneNumber: Lead.PhoneNumber,
      Email: Lead.Email,
      Password: Lead.Password,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const specialtyList = getSpecialtyList();

  const validatePassword = (e: any) => {
    let passwordErrors: string[] = [];
    const passValue = e.target.value;
    let values = form.getFieldsValue();
    values.Password = passValue;
    let correctSteps = [];
    if (passValue.length >= 10) correctSteps.push(1);
    else passwordErrors.push("10 caractéres o más");

    if (/[a-z]/.test(passValue)) correctSteps.push(2);
    else passwordErrors.push("Letras minúsculas del alfabeto (a-z)");

    if (/[A-Z]/.test(passValue)) correctSteps.push(3);
    else passwordErrors.push("Letras maýusculas del alfabeto (A-Z)");

    if (/[0-9]/.test(passValue)) correctSteps.push(4);
    else passwordErrors.push("Números (0-9)");

    if (/[+*$_]/.test(passValue) || passValue.includes("-"))
      correctSteps.push(5);
    else passwordErrors.push("Caractéres especiales, tales como: + - * $ _");

    let level = "";
    let color = "";
    if (correctSteps.length <= 2) {
      level = "I";
      color = "red";
    } else if (correctSteps.length > 2 && correctSteps.length < 5) {
      level = "N";
      color = "orange";
    } else {
      level = "S";
      color = "green";
    }
    const per = (correctSteps.length / 5) * 100;
    dispatch(setPasswordStatus(level, passwordErrors, per, color));
    dispatch(setLeadInformation(values as LeadState));
  };

  const updateState = () => {
    const leadInfo = {
      FirstName: form.getFieldValue("FirstName"),
      LastName: form.getFieldValue("LastName"),
      PhoneNumber: form.getFieldValue("PhoneNumber"),
      Specialty: form.getFieldValue("Specialty"),
      Email: form.getFieldValue("Email"),
      Password: form.getFieldValue("Password"),
    };
    dispatch(setLeadInformation(leadInfo as LeadState));
  };

  const goBack = () => {
    updateState();

    dispatch(showStepOne());
  };

  return (
    <div>
      <Form form={form} layout="vertical">
        <Row>
          <Col xs={24} md={24}>
            <h2>Tu información</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={24}>
            <Form.Item
              name="FirstName"
              label={<Trans id="LeadInformation.Name">Name</Trans>}
              rules={[
                {
                  required: true,
                  message: <Trans id="Common.RequiredFieldError" />,
                },
              ]}
            >
              <Input onBlur={updateState} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={24}>
            <Form.Item
              name="LastName"
              label={<Trans id="LeadInformation.LastName">LastName</Trans>}
              rules={[
                {
                  required: true,
                  message: <Trans id="Common.RequiredFieldError" />,
                },
              ]}
            >
              <Input onBlur={updateState} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={24}>
            <Form.Item
              name="PhoneNumber"
              label={<Trans id="LeadInformation.Phone">Phone</Trans>}
              rules={[
                {
                  required: true,
                  message: <Trans id="Common.RequiredFieldError" />,
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} onBlur={updateState} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={24}>
            <Form.Item
              name="Specialty"
              label={<Trans id="LeadInformation.Specialty">Specialty</Trans>}
              rules={[
                {
                  required: true,
                  message: <Trans id="Common.RequiredFieldError" />,
                },
              ]}
            >
              <Select onChange={updateState}>
                {specialtyList.map((specialty, index) => (
                  <Select.Option key={index} value={specialty.speaciltyID}>
                    {specialty.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={24}>
            <Form.Item
              name="Email"
              label={<Trans id="LeadInformation.Email">Email</Trans>}
              rules={[
                {
                  required: true,
                  message: <Trans id="Common.RequiredFieldError" />,
                },
                {
                  type: "email",
                  message: <Trans id="Common.EmailFieldError" />,
                },
              ]}
            >
              <Input autoComplete="off" onBlur={updateState} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={24}>
            <Form.Item
              name="Password"
              label={<Trans id="LeadInformation.Password">Password</Trans>}
              rules={[
                {
                  required: true,
                  message: <Trans id="Common.RequiredFieldError" />,
                },
              ]}
            >
              <Input.Password onChange={validatePassword} />
            </Form.Item>
          </Col>
        </Row>
        {Lead.Password !== "" ? (
          <Row>
            <Col xs={24} md={18}>
              <Progress
                percent={passwordPercentage}
                steps={5}
                strokeColor={passwordColor}
                size="default"
              />
              {passwordErrors.length > 0 ? (
                <div>
                  <p>
                    <Trans id="Common.PassMsg">Password must contains</Trans>
                  </p>
                  <ul>
                    {passwordErrors.map((error: any, index: number) => {
                      return <li key={index}>{error}</li>;
                    })}
                  </ul>
                </div>
              ) : null}
            </Col>
            <Col xs={24} md={6}>
              {Lead.Password !== "" && passwordLevel === "I" ? (
                <label className="f-r" style={{ color: passwordColor }}>
                  <Trans id="Common.Insecure">Insecure</Trans>
                </label>
              ) : Lead.Password !== "" && passwordLevel === "N" ? (
                <label className="f-r" style={{ color: passwordColor }}>
                  <Trans id="Common.Normal">Normal</Trans>
                </label>
              ) : Lead.Password !== "" && passwordLevel === "S" ? (
                <label className="f-r" style={{ color: passwordColor }}>
                  <Trans id="Common.Strong">Strong</Trans>
                </label>
              ) : null}
            </Col>
          </Row>
        ) : null}
      </Form>
      <Row className="margin-top-10 margin-bottom-10">
        <Col md={24}>
          <Button onClick={goBack} disabled={isProcessingRequest}>
            <Trans id="StepTwoComponent.backBtn">Back</Trans>
          </Button>
        </Col>
      </Row>
    </div>
  );
};
const mapStateToProps = (state: any): State => ({
  accountState: state.accountState,
  signupState: state.signupState,
});
interface State {
  accountState: AccountState;
  signupState: SignupState;
}
export default LeadInformation;
