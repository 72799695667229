import { SubscriptionSubTypeState, SubscriptionSubType } from "./Types";
import { FILTER_PLANS } from "./Actions";

const initialState: SubscriptionSubTypeState = {
  subscriptionSubTypes: [],
  filteredPlans: [],
};

export const stepOneReducer = (
  state = initialState,
  action: any
): SubscriptionSubTypeState => {
  switch (action.type) {
    case FILTER_PLANS:
      return {
        ...state,
        filteredPlans: filterPlanByCountry(
          action.value,
          state.subscriptionSubTypes
        ),
      };
    default:
      return state;
  }
};

const filterPlanByCountry = (value: string, plans: SubscriptionSubType[]) => {
  if (value === null) return plans;
  var newPlans = [];
  if (value === "CRI") {
    newPlans = plans.filter(function (result) {
      return (
        result.planSubTypeId === 2 ||
        result.planSubTypeId === 3 ||
        result.planSubTypeId === 4 ||
        result.planSubTypeId === 5
      );
    });
  } else {
    newPlans = plans.filter(function (result) {
      return result.planSubTypeId === 2 || result.planSubTypeId === 5;
    });
  }
  return newPlans;
};
