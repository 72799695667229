import React, { useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import { Row, Col, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { AccountState } from "../../store/account";
import { useSelector } from "react-redux";
import {
  buildStep2Request,
  createAccountStep2,
} from "../../services/AccountService";
import { logAmplitudeEvent } from "../../utils/amplitude";

const PageStepThree: React.FC = () => {
  const { accountState } = useSelector(mapStateToProps);

  const {
    createAccountStep2Response: response,
    isProcessingRequest,
  } = accountState;

  logAmplitudeEvent("SIGNUP_COMPLETED");

  useEffect(() => {
    const id = accountState.createAccountStep1Response?.Value?.Id || "";

    const req = buildStep2Request();
    createAccountStep2(req, id).then(function (response) {
      if (response && response.Value && response.HasError === false) {
        window.location.href = response.Value.RedirectUrl;
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderResponse = () => {
    const res = response as any;
    if (res !== null) {
      if (res.HasError)
        return (
          <Row align="middle" justify="center" className="m-t-10">
            <Col sm={24} md={24}>
              <h4 className="error-text">{res.Error}</h4>
            </Col>
          </Row>
        );
      else if (res.SuccessMessage)
        return (
          <Row align="middle" justify="center" className="m-t-10">
            <Col sm={24} md={24}>
              <h4>Redireccionando...</h4>
            </Col>
          </Row>
        );
      else return null;
    } else return null;
  };

  return (
    <Row align="middle" className="step3-container">
      <Col md={24} xs={24}>
        {isProcessingRequest === true ? (
          <React.Fragment>
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 36 }} spin />}
            />
            <p className="margin-top-25">
              Por favor espera mientras terminamos de crear tu cuenta, al
              finalizar serás redirigido automáticamente...
            </p>
          </React.Fragment>
        ) : null}
        {renderResponse()}
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: any): State => ({
  accountState: state.accountState,
});

interface State {
  accountState: AccountState;
}
export default PageStepThree;
