import { SignupState } from "./Types";
import { SHOW_STEP_ONE, SHOW_STEP_TWO, SHOW_STEP_THREE } from "./Actions";

const initialState: SignupState = {
  loading: false,
  showOne: true,
  showTwo: false,
  showThree: false,
};

export const signUpReducer = (
  state = initialState,
  action: any
): SignupState => {
  switch (action.type) {
    case SHOW_STEP_ONE:
      return {
        ...state,
        showOne: true,
        showTwo: false,
        showThree: false,
      };
    case SHOW_STEP_TWO:
      return {
        ...state,
        showOne: false,
        showTwo: true,
        showThree: false,
      };
    case SHOW_STEP_THREE:
      return {
        ...state,
        showOne: false,
        showTwo: false,
        showThree: true,
      };
    default:
      return state;
  }
};
