import {
  LeadState,
  Country,
  PlanFromJSON,
  SelectedFeatures,
  BillingState,
} from "./Types";
import {
  NewAccountStep1Response,
  NewAccountStep2Response,
} from "../../services/AccountService/Types";

export const SET_LEAD_INFORMATION = "SET_LEAD_INFORMATION";
export const SET_BILLING_INFORMATION = "SET_BILLING_INFORMATION";
export const SET_CARD_TYPE = "SET_CARD_TYPE";
export const SET_CARD_STATUS = "SET_CARD_STATUS";
export const CLEAN_PLAN_INFORMATION = "CLEAN_PLAN_INFORMATION";
export const SET_LEAD_STATUS = "SET_LEAD_STATUS";
export const SET_PASSWORD_STATUS = "SET_PASSWORD_STATUS";
export const SET_BILLING_ID_STATUS = "SET_BILLING_ID_STATUS";
export const SET_PROCESSING_REQUEST = "SET_PROCESSING_REQUEST";
export const SET_RESPONSE_STEP1_ACCOUNT = "SET_RESPONSE_STEP1_ACCOUNT";
export const SET_RESPONSE_STEP2_ACCOUNT = "SET_RESPONSE_STEP2_ACCOUNT";
export const RESTART_REQUEST = "RESTART_REQUEST";
export const SET_UTMS = "SET_UTMS";
//countries
export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_SELECTED_COUNTRY = "SET_SELECTED_COUNTRY";
export const SET_PLAN_LIST = "SET_PLAN_LIST";
export const SET_SELECTED_PLAN = "SET_SELECTED_PLAN";
export const SET_SELECTED_FEATURES = "SET_SELECTED_FEATURES";
export const FILTER_PLANS = "FILTER_PLANS";

export const setLeadInformation = (lead: LeadState) => ({
  type: SET_LEAD_INFORMATION,
  lead,
});

export const setBillingInformation = (billingDetails: BillingState) => ({
  type: SET_BILLING_INFORMATION,
  billingDetails,
});

export const setCardType = (cardType: string | undefined) => ({
  type: SET_CARD_TYPE,
  cardType,
});

export const setCardStatus = (
  errors: string[],
  isCardInformationValid: boolean
) => ({
  type: SET_CARD_STATUS,
  errors,
  isCardInformationValid,
});

export const cleanPlanInformation = () => ({
  type: CLEAN_PLAN_INFORMATION,
});

export const setCountries = (countries: Country[]) => ({
  type: SET_COUNTRIES,
  countries,
});

export const setSelectedCountry = (countryCode: string) => ({
  type: SET_SELECTED_COUNTRY,
  countryCode,
});

export const setPlanList = (plans: PlanFromJSON[]) => ({
  type: SET_PLAN_LIST,
  plans,
});

export const setSelectedPlan = (
  uniqueId: number,
  isTry: boolean,
  withNutrition: boolean,
  withOdontology: boolean,
  withBilling: boolean
) => ({
  type: SET_SELECTED_PLAN,
  uniqueId,
  isTry,
  withNutrition,
  withOdontology,
  withBilling,
});

export const filterPlanByCountry = (countryCode: string) => ({
  type: FILTER_PLANS,
  countryCode,
});

export const setPasswordStatus = (
  passwordLevel: string,
  passwordErrors: string[],
  passwordPercentage: number,
  passwordColor: string
) => ({
  type: SET_PASSWORD_STATUS,
  passwordLevel,
  passwordErrors,
  passwordPercentage,
  passwordColor,
});

export const setBillingIDStatus = (isBillingIDValid: boolean) => ({
  type: SET_BILLING_ID_STATUS,
  isBillingIDValid,
});

export const setProcessingRequest = (isProcessingRequest: boolean) => ({
  type: SET_PROCESSING_REQUEST,
  isProcessingRequest,
});

export const restartRequest = () => ({
  type: RESTART_REQUEST,
});

export const setStep1Response = (response: NewAccountStep1Response) => ({
  type: SET_RESPONSE_STEP1_ACCOUNT,
  response,
});

export const setStep2Response = (response: NewAccountStep2Response) => ({
  type: SET_RESPONSE_STEP2_ACCOUNT,
  response,
});

export const setSelectedFeatures = (selectedFeatures: SelectedFeatures) => ({
  type: SET_SELECTED_FEATURES,
  selectedFeatures,
});

export const setUtms = (medium: string, campaign: string, source: string) => ({
  type: SET_UTMS,
  medium,
  campaign,
  source,
});
