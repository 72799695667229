import React from "react";
import { StandardLayoutRoute } from "./components/LayoutsRoutes";
import Signup from "./pages/signup/Signup";
import freemium from "./pages/Freemium/Freemium";
import NotFound from "./pages/NotFound";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import WhatsApp from "./pages/WhatsApp/WhatsApp";

const App: React.FC = () => (
  <BrowserRouter>
    <Switch>
      <StandardLayoutRoute
        path="/signup/:planName/:planMode"
        component={Signup}
      />

      <StandardLayoutRoute path="/signup/freemium" component={freemium} />
      <StandardLayoutRoute path="/whatsApp/configuration" component={WhatsApp} />

      <Route path="*" component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default App;
