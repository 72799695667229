import React from "react";
import { Row } from "antd";
import "./PoweredByStyles.less";

const PoweredBy: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <React.Fragment>
      <Row>
        <div style={{ textAlign: "center" }}>
          © {currentYear} Siku. All rights reserved.
        </div>
      </Row>
    </React.Fragment>
  );
};

export default PoweredBy;
