import React from "react";
import { Layout } from "antd";
import PageFooter from "../../Footer";
import PageHeader from "../../Header";

import "./StandardLayoutStyles.less";
import LocaleProvider from "../../utils/LocaleProvider";

const { Content } = Layout;

const StandardLayout: React.FC = ({ children }) => {
  return (
    <LocaleProvider>
      <div className="standard-layout">
        <Layout style={{ background: "#FBFBFB" }}>
          <PageHeader />
          <Content>{children}</Content>
          <PageFooter />
        </Layout>
      </div>
    </LocaleProvider>
  );
};

export default StandardLayout;
