import { Country } from "./Types";
import { NewFreemiumAccountResponse } from "../../services/FreemiumService/Types";
export const FILTER_PLANS = "FILTER_PLANS";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_PLAN_FEATURES = "SET_PLAN_FEATURES";
export const SET_SELECTED_COUNTRY = "SET_SELECTED_COUNTRY";
export const SET_PROCESSING_REQUEST = "SET_PROCESSING_REQUEST";
export const HIDE_FREEMIUM_COMPONENT = "HIDE_FREEMIUM_COMPONENT";
export const SET_NEWFREEMIUMACOUNT_RESPONSE = "SET_NEWFREEMIUMACOUNT_RESPONSE";

export const filterPlanByCountry = (value: string) => ({
  type: FILTER_PLANS,
  value,
});

export const setCountries = (countries: Country[]) => ({
  type: SET_COUNTRIES,
  countries,
});

export const setPlanFeatures = (features: string[]) => ({
  type: SET_PLAN_FEATURES,
  features,
});

export const setSelectedCountry = (countryCode: string) => ({
  type: SET_SELECTED_COUNTRY,
  countryCode,
});

export const setProcessingRequest = (isProcessing: boolean) => ({
  type: SET_PROCESSING_REQUEST,
  isProcessing,
});

export const hideFreemiumComponent = () => ({
  type: HIDE_FREEMIUM_COMPONENT,
});

export const setNewFreemiumAccountResponse = (
  response: NewFreemiumAccountResponse
) => ({
  type: SET_NEWFREEMIUMACOUNT_RESPONSE,
  response,
});
