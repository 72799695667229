import React, { useEffect } from "react";
import MaskedInput from "antd-mask-input";
import { Trans } from "@lingui/react";
import "@ant-design/compatible/assets/index.css";
import {
  Row,
  Col,
  notification,
  Button,
  Form,
  Input,
  InputNumber,
  Spin,
} from "antd";
import "./BillingInformation.css";
import {
  AccountState,
  BillingState,
  setCardType,
  restartRequest,
  setUtms,
  setCardStatus,
  setBillingIDStatus,
  setBillingInformation,
} from "../../store/account";

import { SignupState, showStepThree } from "../../store/signup";
import { useSelector, useDispatch } from "react-redux";
import { LockOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  buildStep1Request,
  createAccountStep1,
} from "../../services/AccountService/AccountService";
import { useQuery } from "../../utils";

const BillingInformation: React.FC = () => {
  const [form] = Form.useForm();
  const { accountState } = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const {
    Billing,
    cardType,
    isCardInformationValid,
    cardErrors,
    isBillingIDValid,
    passwordLevel,
    isProcessingRequest,
    createAccountStep1Response: response,
  } = accountState;

  const query = useQuery();

  const monthsLimit: any = {
    min: 1,
    max: 12,
  };
  const yearsLimit: any = {
    min: new Date().getFullYear(),
    max: 2100,
  };
  const cvvLimit: any = {
    max: 9999,
  };
  const formatMonth = (value: any) => {
    if (value !== undefined) {
      const number = parseInt(value);
      return number < 10 ? `0${value}` : `${value}`;
    }
    return ``;
  };

  useEffect(() => {
    let utm_source = String(query.get("utm_source"));
    let utm_medium = String(query.get("utm_medium"));
    let utm_campaign = String(query.get("utm_campaign"));

    if (utm_medium === "null") utm_medium = "empty";
    if (utm_campaign === "null") utm_campaign = "empty";
    if (utm_source === "null") utm_source = "empty";

    dispatch(setUtms(utm_medium, utm_campaign, utm_source));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onCreditCardChange = (e: any) => {
    const formattedValue = e.target.value;
    const rawValue = formattedValue.replace(/-/g, "").replace(/_/g, "");
    let values = form.getFieldsValue() as BillingState;
    values.CreditCardNumber = rawValue;
    getCreditCardType(rawValue);
    validateCard(values);
  };

  const onMonthChange = (e: any) => {
    let values = form.getFieldsValue() as BillingState;
    values.ExpirationMonth = e;
    validateCard(values);
  };

  const onYearChange = (e: any) => {
    let values = form.getFieldsValue() as BillingState;
    values.ExpirationYear = e;
    validateCard(values);
  };

  const onCVVChange = (e: any) => {
    let values = form.getFieldsValue() as BillingState;
    values.CVV = e;
    validateCard(values);
  };

  const getCreditCardType = (value: number) => {
    const creditCardNumber = value ? value.toString() : "";
    let type = undefined;
    if (creditCardNumber[0] === "5") {
      type = "mastercard";
    }
    // then check for Visa
    else if (creditCardNumber[0] === "4") {
      type = "visa";
    }
    dispatch(setCardType(type));
  };

  const validateCard = (values: BillingState) => {
    let errors: string[] = [];

    var isCardInformationValid = true;

    let cardNumber = values.CreditCardNumber ? values.CreditCardNumber : "";
    cardNumber = cardNumber.replace(/-/g, "").replace(/_/g, "");

    if (
      cardNumber.length === 16 &&
      cardNumber[0] !== "4" &&
      cardNumber.substr(0, 2) !== "51" &&
      cardNumber.substr(0, 2) !== "52" &&
      cardNumber.substr(0, 2) !== "53" &&
      cardNumber.substr(0, 2) !== "54" &&
      cardNumber.substr(0, 2) !== "55" &&
      cardNumber.substr(0, 6) !== "222100" &&
      cardNumber.substr(0, 6) !== "272099"
    ) {
      isCardInformationValid = false;
      errors.push("Tipo de tarjeta no válida, debe ser Visa o Mastercard");
    }

    if (cardNumber.length !== 16) {
      isCardInformationValid = false;
      errors.push("Número de tarjeta no válido");
    }

    if (
      values.ExpirationMonth === null ||
      values.ExpirationMonth === undefined
    ) {
      isCardInformationValid = false;
    }

    if (
      values.ExpirationMonth &&
      (values.ExpirationMonth > 12 || values.ExpirationMonth < 1)
    ) {
      isCardInformationValid = false;
      errors.push("Mes de vencimiento no válido");
    }

    const yearMin = new Date().getFullYear();

    if (values.ExpirationYear === null || values.ExpirationYear === undefined) {
      isCardInformationValid = false;
    }
    if (values.ExpirationYear && values.ExpirationYear < yearMin) {
      isCardInformationValid = false;
      errors.push(`Tarjeta vencida`);
    }

    const monthMin = new Date().getMonth() + 1;
    if (
      values.ExpirationYear === yearMin &&
      values.ExpirationMonth &&
      values.ExpirationMonth <= monthMin
    ) {
      isCardInformationValid = false;
      errors.push("Tarjeta vencida");
    }

    if (values.CVV === null || values.CVV === undefined) {
      isCardInformationValid = false;
    }
    if (values.CVV && (values.CVV > 9999)) {
      isCardInformationValid = false;
      errors.push("Código de seguridad no válido");
    }

    dispatch(setCardStatus(errors, isCardInformationValid));
  };

  const onBillingIDChange = (e: any) => {
    if (e === null) return;
    const idValue = e.toString();
    if (idValue.length >= 9 && idValue.length < 20)
      dispatch(setBillingIDStatus(true));
    else dispatch(setBillingIDStatus(false));

    let values = form.getFieldsValue() as BillingState;
    values.BillingId = idValue;
  };

  const onActivateAccount = () => {
    let error = false;
    let msg = "";

    if (accountState.isLeadFormValid === false) {
      error = true;
      msg = "Tu información personal no está completa.";
    }

    if (passwordLevel !== "S") {
      error = true;
      msg = "La contraseña no es segura.";
    }

    if (isBillingIDValid === false) {
      error = true;
      msg = "La identificación para facturación no es válida.";
    }
    if (isCardInformationValid === false) {
      error = true;
      msg = "La información de la tarjeta no es válida.";
    }

    var billingCardHolder = form.getFieldValue("FullName");
    if (
      billingCardHolder === undefined ||
      billingCardHolder === null ||
      billingCardHolder === ""
    ) {
      error = true;
      msg = "El nombre y apellidos del dueño de la tarjeta es requerido.";
    }

    if (error) {
      notification["error"]({
        message: "¡Atención!",
        description: msg,
      });
      return;
    }

    //update state
    const leadInfo = {
      FullName: billingCardHolder,
      CreditCardNumber: form.getFieldValue("CreditCardNumber"),
      ExpirationYear: form.getFieldValue("ExpirationYear"),
      ExpirationMonth: form.getFieldValue("ExpirationMonth"),
      CVV: form.getFieldValue("CVV"),
      BillingId: form.getFieldValue("BillingId"),
    };
    dispatch(setBillingInformation(leadInfo as BillingState));

    dispatch(restartRequest());

    const req = buildStep1Request();
    createAccountStep1(req).then(function (response) {
      if (response && response.Value && response.HasError === false) {
        dispatch(showStepThree());
      }
    });
  };

  const activateBtn = () => {
    const res = response as any;

    if (res === null || (res !== null && !res.SuccessMessage)) {
      const btn = (
        <Button
          block
          type="primary"
          onClick={onActivateAccount}
          disabled={isProcessingRequest}
        >
          <Trans id="BillingInformation.BtnActivate">Activate</Trans>
        </Button>
      );
      return btn;
    } else return null;
  };

  const renderResponse = () => {
    const res = response as any;
    if (res !== null) {
      if (res.HasError)
        return (
          <Row align="middle" justify="center" className="m-t-10">
            <Col sm={24} md={24}>
              <h4 className="error-text">{res.Error}</h4>
            </Col>
          </Row>
        );
      else if (res.SuccessMessage)
        return (
          <Row align="middle" justify="center" className="m-t-10">
            <Col sm={24} md={24}>
              <h4>¡Primer paso listo!</h4>
            </Col>
          </Row>
        );
      else return null;
    } else return null;
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div>
      <Row>
        <Col xs={24} md={24}>
          <h2>
            <Trans id="BillingInformation.Title" /> <LockOutlined />
          </h2>
        </Col>
      </Row>
      <Form form={form} layout="vertical">
        <Row>
          <Col xs={24} md={24}>
            <Form.Item
              label={<Trans id="BillingInformation.FullName">Full name</Trans>}
              name="FullName"
              rules={[
                {
                  required: true,
                  message: <Trans id="Common.RequiredFieldError" />,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={24}>
            <Form.Item
              label={
                <Trans id="BillingInformation.CreditCardNumber">
                  CreditCard number
                </Trans>
              }
              name="CreditCardNumber"
              rules={[
                {
                  required: true,
                  message: <Trans id="Common.RequiredFieldError" />,
                },
              ]}
            >
              <MaskedInput
                mask="1111-1111-1111-1111"
                name="card"
                size={"large"}
                className={!isCardInformationValid ? "error-input" : ""}
                onChange={onCreditCardChange}
              />
            </Form.Item>
            <Row className="cardImgRow">
              {!cardType ? (
                <Col xs={24} span={24}>
                  <div className="f-r">
                    <img src="../../../cardsList1.png" width={75} alt="" />
                  </div>
                </Col>
              ) : cardType && cardType === "visa" ? (
                <Col xs={24} span={24}>
                  <div className="f-r">
                    <img src="../../../visa.png" className="cardImg" alt="" />
                  </div>
                </Col>
              ) : (
                <Col xs={24} span={24}>
                  <div className="f-r">
                    <img
                      src="../../../mastercard.png"
                      className="cardImg"
                      alt=""
                    />
                  </div>
                </Col>
              )}
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xs={24} md={24}>
            <label>
              <Trans id="BillingInformation.ExpirationDate">
                Expiration card date
              </Trans>
            </label>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={8}>
            <Form.Item
              label={
                <Trans id="BillingInformation.ExpirationMonth">
                  Expiration month
                </Trans>
              }
              name="ExpirationMonth"
              rules={[
                {
                  required: true,
                  message: <Trans id="Common.RequiredFieldError" />,
                },
              ]}
            >
              <InputNumber
                min={monthsLimit.min}
                max={monthsLimit.max}
                formatter={(value) => formatMonth(value)}
                onChange={onMonthChange}
                placeholder="MM"
                className={!isCardInformationValid ? "error-input" : ""}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              label={
                <Trans id="BillingInformation.ExpirationYear">
                  Expiration year
                </Trans>
              }
              name="ExpirationYear"
              rules={[
                {
                  required: true,
                  message: <Trans id="Common.RequiredFieldError" />,
                },
              ]}
            >
              <InputNumber
                min={yearsLimit.min}
                max={yearsLimit.max}
                onChange={onYearChange}
                placeholder="AAAA"
                className={!isCardInformationValid ? "error-input" : ""}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="CVV"
              label={
                <Trans id="BillingInformation.CVV">Card security code</Trans>
              }
              rules={[
                {
                  required: true,
                  message: <Trans id="Common.RequiredFieldError" />,
                },
              ]}
            >
              <InputNumber
                onChange={onCVVChange}
                max={cvvLimit.max}
                className={!isCardInformationValid ? "error-input" : ""}
              />
            </Form.Item>
          </Col>
        </Row>
        {cardErrors && cardErrors.length > 0 ? (
          <Row className="errors-block">
            <Col xs={24} md={24}>
              <ul>
                {cardErrors.map((error, index) => {
                  return <li key={index}>{error}</li>;
                })}
              </ul>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col xs={24} md={24}>
            <h4>
              <Trans id="BillingInformation.TitleAddress" /> (
              {accountState.selectedCountry === undefined
                ? ""
                : accountState.selectedCountry.name}
              )
            </h4>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={24}>
            <Form.Item
              name="BillingId"
              label={
                <Trans id="BillingInformation.BillingId">
                  Identification number
                </Trans>
              }
              rules={[
                {
                  required: true,
                  message: <Trans id="Common.RequiredFieldError" />,
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                onChange={onBillingIDChange}
                className={!isBillingIDValid ? "error-input" : ""}
              />
            </Form.Item>
            {!isBillingIDValid && Billing.BillingId !== "" ? (
              <Row className="row-error">
                <label className="error-text">
                  <Trans id="BillingInformation.BillingIdError">
                    Billing id must has at least 9 digits and 20 max
                  </Trans>
                </label>
              </Row>
            ) : null}
          </Col>
        </Row>
      </Form>
      <Row>
        <Col xs={24} md={24}>
          <p>
            Al enviar este formulario, aceptas los{" "}
            {accountState.selectedCountry?.code === "COL"
                ? <a
                href="https://www.sikumed.com.co/condiciones-del-servicio/?utm_source=account&utm_medium=none&utm_campaign=none"
                target="_blank"
                rel="noopener noreferrer"
              >
                Términos y condiciones
              </a>
                :  <a
                href="https://www.sikumed.com/condiciones-del-servicio/?utm_source=account&utm_medium=none&utm_campaign=none"
                target="_blank"
                rel="noopener noreferrer"
              >
                Términos y condiciones
              </a>}
           {" "}
            del servicio y el procesamiento de sus datos personales, tal y como
            se describe en la{" "}
            {accountState.selectedCountry?.code === "COL"
                ? <a
              href="https://www.sikumed.com.co/politicas-de-privacidad?utm_source=account&utm_medium=none&utm_campaign=none"
              target="_blank"
              rel="noopener noreferrer"
            >
              Política de privacidad
            </a>
            :
            <a
              href="https://www.sikumed.com/politica-de-privacidad?utm_source=account&utm_medium=none&utm_campaign=none"
              target="_blank"
              rel="noopener noreferrer"
            >
              Política de privacidad
            </a>}
            .
          </p>
        </Col>
      </Row>
      {accountState.SelectedPlan.IsTry === false && (
        <Row className="total-box-leyend">
          <Col xs={16} md={16}>
            <Trans render="strong" id="StepOneComponent.TodayTotalLeyend">
              Today's charge
            </Trans>
          </Col>
          <Col xs={8} md={8} className="info-box-pricing">
            <h3 className="info-box-pricing">
            {accountState.selectedCountry?.currency} {accountState.SelectedPlan.TotalWithTax}
            </h3>
          </Col>
        </Row>
      )}
      <Row className="margin-top-10">
        <Col xs={24} md={24}>
          {activateBtn()}
        </Col>
      </Row>
      {isProcessingRequest === true ? (
        <Row
          align="middle"
          style={{ textAlign: "center" }}
          className="margin-top-10"
        >
          <Col xs={24} md={24}>
            <Spin indicator={antIcon} />
          </Col>
        </Row>
      ) : null}
      {renderResponse()}
    </div>
  );
};

const mapStateToProps = (state: any): State => ({
  accountState: state.accountState,
  signupState: state.signupState,
});
interface State {
  accountState: AccountState;
  signupState: SignupState;
}
export default BillingInformation;
