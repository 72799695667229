
export function loadFacebookSdk(): Promise<void> {
  return new Promise((resolve) => {
    // Ensure the fbAsyncInit function is defined on the window object
    window.fbAsyncInit = function () {
      FB.init({
        appId: process.env.REACT_APP_META_WHATSAPP_APP_ID ?? '', // Replace with your actual App ID
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v19.0'
      });
      resolve();
    };

    // Load the SDK script
    (function (d: Document, s: string, id: string) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;

      const js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode?.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  });
}
