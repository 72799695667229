import store from "../../store";
import {
  setProcessingRequest,
  setNewFreemiumAccountResponse,
} from "../../store/Freemium/Actions";
import httpClient from "../AxiosWrapper";
import { NewAccountFreemiumRequest, NewFreemiumAccountResponse } from "./Types";

export const createFreemiumAccount = async (
  request: NewAccountFreemiumRequest
) => {
  store.dispatch(setProcessingRequest(true));

  const url =
    process.env.REACT_APP_COMMON_API_URL + "/Api/Account/V1/Account/Freemium";

  const response = await httpClient
    .post<NewFreemiumAccountResponse>(url, request)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.response) {
        const errorResponse: NewFreemiumAccountResponse = {
          HasError: true,
          Error: `Error at request ${err.response.status}:${err.response.data} `,
          SuccessMessage: null,
          Value: null,
        };
        return errorResponse;
      } else {
        const unknownErrorResponse: NewFreemiumAccountResponse = {
          HasError: true,
          Error: `Unexpected error, please try again`,
          SuccessMessage: null,
          Value: null,
        };
        return unknownErrorResponse;
      }
    });

  store.dispatch(setNewFreemiumAccountResponse(response));
  store.dispatch(setProcessingRequest(false));

  return response;
};
