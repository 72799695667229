import axios from "axios";
import { HttpClient } from "../config";

const httpClient = axios.create(HttpClient);

httpClient.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default httpClient;
