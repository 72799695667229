import React, { useEffect } from "react";
import { Trans } from "@lingui/react";
import "@ant-design/compatible/assets/index.css";
import {
  Row,
  Col,
  Select,
  Button,
  Skeleton,
  Form,
  Input,
  InputNumber,
  Spin,
  notification,
} from "antd";
import "./Freemium.less";
import { useSelector, useDispatch } from "react-redux";
import { fetchCountries } from "../../services/CountryService";
import { FreemiumState } from "../../store/Freemium";
import {
  setCountries,
  setPlanFeatures,
  setSelectedCountry,
  hideFreemiumComponent,
} from "../../store/Freemium";

import { getSpecialtyList } from "../../services/SpecialtyService";
import { createFreemiumAccount } from "../../services/FreemiumService";
import { fetchPlans } from "../../services/SubscriptionService";
import { useQuery } from "../../utils/UseQuery";
import { LoadingOutlined } from "@ant-design/icons";
import { logAmplitudeEvent } from "../../utils/amplitude";
import { AccountState } from "../../store/account";

const PageFreemium: React.FC = () => {
  const { freemiumState, accountState } = useSelector(mapStateToProps);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const query = useQuery();
  const specialtyList = getSpecialtyList();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const countryFromUrl = String(query.get("country"));
  const COSTA_RICA_CODE_IDENTIFIER = "CRI";
  //use effects

  useEffect(() => {
    fetchPlans().then(function (response) {
      var filteredFeatures = response.filter((x) => x.uniqueId === 99)[0];
      dispatch(setPlanFeatures(filteredFeatures.features));
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (freemiumState.Countries.length === 0) {
      fetchCountries().then(function (response) {
        dispatch(setCountries(response));
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      accountState.Countries.length > 0 &&
      accountState.selectedCountryCode === ""
    ) {
      var defaultCountry = countryFromUrl;

      if (defaultCountry === "null")
        defaultCountry = COSTA_RICA_CODE_IDENTIFIER;

      dispatch(setSelectedCountry(defaultCountry));
      form.setFieldsValue({
        selectedCountryCode: defaultCountry,
      });
    }
  }, [accountState.Countries]);

  //user events handlers
  const onCountryChange = (countryCode: any) => {
    dispatch(setSelectedCountry(countryCode));
  };

  const onContinue = () => {
    let utm_source = String(query.get("utm_source"));
    let utm_medium = String(query.get("utm_medium"));
    let utm_campaign = String(query.get("utm_campaign"));

    if (utm_medium === "null") utm_medium = "empty";
    if (utm_campaign === "null") utm_campaign = "empty";
    if (utm_source === "null") utm_source = "empty";

    const request = {
      Lead: {
        FirstName: form.getFieldValue("FirstName"),
        LastName: form.getFieldValue("LastName"),
        CountryCode: form.getFieldValue("selectedCountryCode"),
        PhoneNumber: form.getFieldValue("PhoneNumber"),
        Specialty: form.getFieldValue("Specialty"),
        Email: form.getFieldValue("Email"),
      },
      Utm: {
        UTMCampaignName: utm_campaign,
        UTMCampaignMedium: utm_medium,
        UTMCampaignSource: utm_source,
      },
    };

    form
      .validateFields()
      .then(() => {})
      .catch();

    var error = false;
    var msg = "";
    if (
      request.Lead.PhoneNumber == null ||
      request.Lead.PhoneNumber === "" ||
      request.Lead.Email == null ||
      request.Lead.Email === "" ||
      request.Lead.FirstName == null ||
      request.Lead.FirstName === "" ||
      request.Lead.LastName == null ||
      request.Lead.LastName === "" ||
      request.Lead.CountryCode == null ||
      request.Lead.CountryCode === "" ||
      request.Lead.Specialty == null ||
      request.Lead.Specialty === 0
    ) {
      error = true;
      msg = "Falta información por completar";
    }

    if (error) {
      notification["error"]({
        message: "¡Atención!",
        description: msg,
      });
      return;
    }

    createFreemiumAccount(request).then(function (response) {
      if (response.HasError === false) {
        logAmplitudeEvent("FREEMIUM_COMPLETED");
        dispatch(hideFreemiumComponent());
      }
    });
  };

  const renderResponse = () => {
    const res = freemiumState.newFreemiumAccountResponse as any;
    if (res !== null) {
      if (res.HasError)
        return (
          <Row align="middle" justify="center" className="m-t-10">
            <Col sm={24} md={24}>
              <h4 className="error-text">{res.Error}</h4>
            </Col>
          </Row>
        );
      else if (res.SuccessMessage) return null;
      else return null;
    } else return null;
  };

  return (
    <Skeleton loading={false} active>
      {freemiumState.showSuccessMessage === false && (
        <Row>
          <Col md={11} xs={24}>
            <div>
              <h2>
                <Trans render="span" id="FreemiumComponent.QueIncluyeTitle">
                  ¿Qué Incluye?
                </Trans>
              </h2>
              <Form form={form} layout="vertical">
                <Row>
                  <Col>
                    {freemiumState.PlanFeaturesFromJSON !== undefined && (
                      <div>
                        {freemiumState.PlanFeaturesFromJSON.map(
                          (feature, index) => (
                            <div key={index}>
                              <span className="feature">{feature}</span>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="margin-botton-10">
                  <Col>
                    <Trans
                      render="span"
                      className="leyend"
                      id="FreemiumComponent.FeaturesLeyend"
                    >
                      Some restrictions may apply
                    </Trans>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
          <Col md={2}>
            <div className="divider"></div>
          </Col>
          <Col md={11} xs={24}>
            <div>
              <h2>
                <Trans render="span" id="FreemiumComponent.YourInfomationTitle">
                  Your Information
                </Trans>
              </h2>
              <Form form={form} layout="vertical">
                <Row>
                  <Col xs={24}>
                    <Form.Item
                      name="selectedCountryCode"
                      label={
                        <Trans id="FreemiumComponent.Country">Country</Trans>
                      }
                      rules={[
                        {
                          required: true,
                          message: <Trans id="Common.RequiredFieldError" />,
                        },
                      ]}
                    >
                      <Select onChange={onCountryChange}>
                        {freemiumState.Countries.map((countries, index) => (
                          <Select.Option key={index} value={countries.code}>
                            {countries.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={24}>
                    <Form.Item
                      name="FirstName"
                      label={<Trans id="FreemiumComponent.Name">Name</Trans>}
                      rules={[
                        {
                          required: true,
                          message: <Trans id="Common.RequiredFieldError" />,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={24}>
                    <Form.Item
                      name="LastName"
                      label={
                        <Trans id="FreemiumComponent.LastName">LastName</Trans>
                      }
                      rules={[
                        {
                          required: true,
                          message: <Trans id="Common.RequiredFieldError" />,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={24}>
                    <Form.Item
                      name="PhoneNumber"
                      label={<Trans id="FreemiumComponent.Phone">Phone</Trans>}
                      rules={[
                        {
                          required: true,
                          message: <Trans id="Common.RequiredFieldError" />,
                        },
                      ]}
                    >
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={24}>
                    <Form.Item
                      name="Specialty"
                      label={
                        <Trans id="FreemiumComponent.Specialty">
                          Specialty
                        </Trans>
                      }
                      rules={[
                        {
                          required: true,
                          message: <Trans id="Common.RequiredFieldError" />,
                        },
                      ]}
                    >
                      <Select>
                        {specialtyList.map((specialty, index) => (
                          <Select.Option
                            key={index}
                            value={specialty.speaciltyID}
                          >
                            {specialty.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={24}>
                    <Form.Item
                      name="Email"
                      label={<Trans id="FreemiumComponent.Email">Email</Trans>}
                      rules={[
                        {
                          required: true,
                          message: <Trans id="Common.RequiredFieldError" />,
                        },
                        {
                          type: "email",
                          message: <Trans id="Common.EmailFieldError" />,
                        },
                      ]}
                    >
                      <Input autoComplete="off" /* onBlur={updateState} */ />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={24}>
                    <p>
                      Al enviar este formulario, aceptas los{" "}
                      {accountState.selectedCountry?.code === "COL" ? (
                        <a
                          href="https://www.sikumed.com.co/condiciones-del-servicio/?utm_source=account&utm_medium=none&utm_campaign=none"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Términos y condiciones
                        </a>
                      ) : (
                        <a
                          href="https://www.sikumed.com/condiciones-del-servicio/?utm_source=account&utm_medium=none&utm_campaign=none"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Términos y condiciones
                        </a>
                      )}{" "}
                      del servicio y el procesamiento de sus datos personales,
                      tal y como se describe en la{" "}
                      {accountState.selectedCountry?.code === "COL" ? (
                        <a
                          href="https://www.sikumed.com.co/politicas-de-privacidad?utm_source=account&utm_medium=none&utm_campaign=none"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Política de privacidad
                        </a>
                      ) : (
                        <a
                          href="https://www.sikumed.com/politica-de-privacidad?utm_source=account&utm_medium=none&utm_campaign=none"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Política de privacidad
                        </a>
                      )}
                      .
                    </p>
                  </Col>
                </Row>
                {freemiumState.isProcessing === true ? (
                  <Row
                    align="middle"
                    style={{ textAlign: "center" }}
                    className="margin-top-10"
                  >
                    <Col xs={24} md={24}>
                      <Spin indicator={antIcon} />
                    </Col>
                  </Row>
                ) : (
                  renderResponse()
                )}

                <Row className="margin-top-10">
                  <Col xs={24} md={24}>
                    <Button
                      block
                      type="primary"
                      onClick={onContinue}
                      disabled={freemiumState.isProcessing}
                    >
                      <Trans id="FreemiumComponent.BtnActivate">Activate</Trans>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      )}
      {freemiumState.showSuccessMessage === true && (
        <Row align="middle" className="freemium-container">
          <Col md={24} xs={24}>
            <p className="margin-top-25">
              Gracias por activar nuestra versión gratuita, para terminar el
              proceso verifica tu buzón{" "}
              <strong>{form.getFieldValue("Email")}</strong>
            </p>
          </Col>
        </Row>
      )}
    </Skeleton>
  );
};
export default PageFreemium;

const mapStateToProps = (state: any): State => ({
  freemiumState: state.freemiumState,
  accountState: state.accountState,
});

interface State {
  freemiumState: FreemiumState;
  accountState: AccountState;
}
