import React, { useEffect } from 'react';
import { loadFacebookSdk } from '../../utils/loadFacebookSdk';
import { Button } from 'antd'; // Import Button from antd

const WhatsAppSignup: React.FC = () => {
    const configurationID = process.env.REACT_APP_META_WHATSAPP_CONFIGURATION_ID ?? '';
    useEffect(() => {
        loadFacebookSdk().then(() => {
            console.log('Facebook SDK loaded');
        });
    }, []);

    const launchWhatsAppSignup = () => {
        FB.login(function (response) {
            if (response.authResponse) {
                const accessToken = response.authResponse.accessToken;
                console.log('response:', response);
                // Use this token to call the debug_token API and get the shared WABA's ID
                console.log('Access Token:', accessToken);
            } else {
                console.log('User cancelled login or did not fully authorize.');
            }
        }, {
            config_id: configurationID, // Configuration ID obtained in the previous step goes here
            response_type: 'code',    // Must be set to 'code' for System User access token
            override_default_response_type: true,
            extras: {
                setup: {
                    // Prefilled data can go here
                }
            }
        });
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button onClick={launchWhatsAppSignup} type="primary">
                Login with Facebook
            </Button>
        </div>
    );
};

export default WhatsAppSignup;
