import React from "react";
import { Layout } from "antd";

import PageFreemium from "../../components/Freemium/Freemium";
import { logAmplitudeEvent } from "../../utils/amplitude";

const Freemium: React.FC = () => {
  const { Content } = Layout;

  logAmplitudeEvent("NAV_FREEMIUMPAGE");

  return (
    <React.Fragment>
      <Content className="site-layout">
        <h1 className="title">Tu cuenta gratuita de Siku</h1>
        <div className="site-layout-content" style={{ background: "#fff" }}>
          <PageFreemium />
        </div>
      </Content>
    </React.Fragment>
  );
};

export default Freemium;
