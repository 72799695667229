export const getSpecialtyList = () => {
  return [
    { speaciltyID: 1, name: "Odontología" },
    { speaciltyID: 2, name: "Medicina general" },
    { speaciltyID: 3, name: "Fisioterapia" },
    { speaciltyID: 4, name: "Nutrición" },
    { speaciltyID: 5, name: "Psicología" },
    { speaciltyID: 6, name: "Dermatología" },
    { speaciltyID: 99, name: "Otra especialidad" },
  ];
};
