import { setupI18n } from "@lingui/core";
import { useLocation } from "react-router-dom";

export * from "./IdTypes";
export * from "./NameOfFactory";
export * from "./Parameters";
export * from "./UseQuery";

export const i18n = setupI18n();

export const useQuery = () => new URLSearchParams(useLocation().search);
