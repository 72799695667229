import { PlanFromJSON } from "../../store/account/Types";
import httpClient from "../AxiosWrapper";

export const fetchPlans = async () => {
  try {
    const response = await httpClient.get<PlanFromJSON[]>(
      "/plans.json?v=20210920"
    );

    return response.data;
  } catch {
    return [];
  }
};
