import {
  SET_LEAD_INFORMATION,
  SET_UTMS,
  SET_CARD_TYPE,
  CLEAN_PLAN_INFORMATION,
  SET_COUNTRIES,
  SET_SELECTED_COUNTRY,
  SET_PLAN_LIST,
  SET_SELECTED_PLAN,
  FILTER_PLANS,
  SET_PASSWORD_STATUS,
  SET_PROCESSING_REQUEST,
  SET_RESPONSE_STEP1_ACCOUNT,
  RESTART_REQUEST,
  SET_SELECTED_FEATURES,
  SET_CARD_STATUS,
  SET_BILLING_ID_STATUS,
  SET_BILLING_INFORMATION,
  SET_RESPONSE_STEP2_ACCOUNT,
} from "./Actions";

import { Country } from "./../../services/CountryService/Types";

import {
  LeadState,
  AccountState,
  BillingState,
  PlanState,
  UtmState,
  PlanFromJSON,
  SelectedFeatures,
} from "./Types";

const leadInitialState: LeadState = {
  FirstName: "",
  LastName: "",
  Email: "",
  Password: "",
  PhoneNumber: undefined,
  Specialty: undefined,
};

const billingInitialState: BillingState = {
  FullName: "",
  CreditCardNumber: "",
  ExpirationYear: 0,
  ExpirationMonth: 0,
  CVV: 0,
  BillingId: "",
};

const selectedPlanInitialState: PlanState = {
  PlanId: 0,
  PlanSubTypeId: 0,
  Total: 0,
  Pricing: 0,
  Tax: 0,
  IsTry: false,
  UseAgenda: false,
  AgendaProfessionals: 0,
  UseBilling: false,
  UseNutrition: false,
  UseOdontology: false,
  TotalByAgenda: 0,
  TotalByBilling: 0,
  TotalByNutrition: 0,
  TotalByOdontology: 0,
  TaxAmount: 0,
  TotalWithTax: 0,
  SubTotal: 0,
};

const utmInitialState: UtmState = {
  UTMCampaignName: "",
  UTMCampaignMedium: "",
  UTMCampaignSource: "",
};

const defaultBasePlanFromJSON: PlanFromJSON = {
  agenda: {
    extraHealthProviderCost: 0,
    healthProvidersIncluded: 0,
    isAllowed: false,
    description: "",
  },
  billing: {
    companiesIncluded: 0,
    extraCompanyPrice: 0,
    isAllowed: false,
    cost: 0,
    description: "",
  },
  ehr: {
    extraHealthProviderCost: 0,
    healthProvidersIncluded: 0,
    isAllowed: false,
    description: "",
  },
  features: [],
  name: "",
  nutrition: {
    cost: 0,
    isAllowed: false,
  },
  odontology: {
    cost: 0,
    isAllowed: false,
  },
  planId: 0,
  planSubTypeId: 0,
  pricing: 0,
  uniqueId: 0,
};

const reducerInitialState: AccountState = {
  Lead: leadInitialState, //lead info to perform request
  Billing: billingInitialState, //billing info to perform request
  SelectedPlan: selectedPlanInitialState, //plan info to perform request
  Utm: utmInitialState,
  PlansFromJSON: [],
  FilteredPlansFromJSON: [],
  BasePlanFromJSON: defaultBasePlanFromJSON,
  Countries: [],
  selectedCountryCode: "",
  selectedCountry: undefined,
  passwordLevel: "",
  passwordErrors: [],
  passwordPercentage: 0,
  passwordColor: "",
  cardType: undefined,
  cardErrors: [],
  isLeadFormValid: false,
  isProcessingRequest: false,
  createAccountStep1Response: null,
  createAccountStep2Response: null,
  isBillingIDValid: false,
  isCardInformationValid: false,
};

export const accountReducer = (
  state = reducerInitialState,
  action: any
): AccountState => {
  switch (action.type) {
    case SET_LEAD_INFORMATION:
      var isLeadFormValid = getIsLeadFormValid(action.lead);

      return {
        ...state,
        Lead: action.lead,
        isLeadFormValid,
      };

    case SET_BILLING_INFORMATION:
      return {
        ...state,
        Billing: action.billingDetails,
      };

    case CLEAN_PLAN_INFORMATION:
      return {
        ...state,
        SelectedPlan: selectedPlanInitialState,
      };
    case SET_CARD_TYPE:
      return {
        ...state,
        cardType: action.cardType,
      };
    case SET_CARD_STATUS:
      return {
        ...state,
        cardErrors: action.errors,
        isCardInformationValid: action.isCardInformationValid,
      };
    case SET_COUNTRIES:
      return {
        ...state,
        Countries: action.countries.sort(),
      };
    case SET_SELECTED_COUNTRY:
      var selected = state.Countries.filter(
        (x) => x.code === action.countryCode
      )[0];

      return {
        ...state,
        selectedCountryCode: action.countryCode,
        selectedCountry: selected,
      };
    case SET_PLAN_LIST:
      return {
        ...state,
        PlansFromJSON: action.plans.sort(),
      };
    case FILTER_PLANS:
      var filteredPlans = filterPlanByCountry(
        action.countryCode,
        state.PlansFromJSON,
        state.Countries
      );
      return {
        ...state,
        FilteredPlansFromJSON: filteredPlans,
      };
    case SET_SELECTED_PLAN:
      var tax =
        state.selectedCountry === undefined ? 0 : state.selectedCountry.tax;

      return {
        ...state,
        SelectedPlan: buildSelectedPlanState(
          action.uniqueId,
          state.FilteredPlansFromJSON,
          tax,
          action.isTry,
          action.withNutrition,
          action.withOdontology,
          action.withBilling
        ),
        BasePlanFromJSON: state.FilteredPlansFromJSON.filter(
          (x) => x.uniqueId === action.uniqueId
        )[0],
      };
    case SET_SELECTED_FEATURES:
      return {
        ...state,
        SelectedPlan: updateSelectedPlanWithSelectedFeatures(
          state.SelectedPlan,
          state.BasePlanFromJSON,
          action.selectedFeatures
        ),
      };
    case SET_PASSWORD_STATUS:
      return {
        ...state,
        passwordLevel: action.passwordLevel,
        passwordErrors: action.passwordErrors,
        passwordPercentage: action.passwordPercentage,
        passwordColor: action.passwordColor,
      };
    case SET_BILLING_ID_STATUS:
      return {
        ...state,
        isBillingIDValid: action.isBillingIDValid,
      };
    case SET_PROCESSING_REQUEST:
      return {
        ...state,
        isProcessingRequest: action.isProcessingRequest,
      };
    case SET_RESPONSE_STEP1_ACCOUNT:
      return {
        ...state,
        createAccountStep1Response: action.response,
      };
    case SET_RESPONSE_STEP2_ACCOUNT:
      return {
        ...state,
        createAccountStep2Response: action.response,
      };
    case RESTART_REQUEST:
      return {
        ...state,
        createAccountStep1Response: null,
      };
    case SET_UTMS:
      return {
        ...state,
        Utm: {
          UTMCampaignMedium: action.medium,
          UTMCampaignName: action.campaign,
          UTMCampaignSource: action.source,
        },
      };
    default:
      return state;
  }
};

const updateSelectedPlanWithSelectedFeatures = (
  plan: PlanState,
  selectedPlanFromJSON: PlanFromJSON,
  selectedFeatures: SelectedFeatures
) => {
  var basePlan = {
    ...plan,
  };

  basePlan.UseAgenda = selectedFeatures.UseAgenda;
  basePlan.UseBilling = selectedFeatures.UseBilling;
  basePlan.UseNutrition = selectedFeatures.UseNutrition;
  basePlan.UseOdontology = selectedFeatures.UseOdontology;

  return calculatePlanTotals(basePlan, selectedPlanFromJSON);
};

const buildSelectedPlanState = (
  uniqueId: number,
  plans: PlanFromJSON[],
  tax: number,
  isTry: boolean,
  withNutrition: boolean,
  withOdontology: boolean,
  withBilling: boolean
) => {
  var baseResult = { ...selectedPlanInitialState };

  var planDetails = plans.filter((x) => x.uniqueId === uniqueId)[0];

  //agenda
  baseResult.UseAgenda = planDetails.agenda.isAllowed;
  baseResult.AgendaProfessionals = planDetails.agenda.healthProvidersIncluded;

  //billing
  baseResult.UseBilling = planDetails.billing.isAllowed && withBilling;

  baseResult.UseNutrition = planDetails.nutrition.isAllowed && withNutrition;
  baseResult.UseOdontology = planDetails.odontology.isAllowed && withOdontology;

  baseResult.PlanId = planDetails.planId;
  baseResult.PlanSubTypeId = planDetails.planSubTypeId;
  baseResult.Pricing = planDetails.pricing;

  baseResult.Tax = tax;

  baseResult.IsTry = isTry;

  return calculatePlanTotals(baseResult, planDetails);
};

const calculatePlanTotals = (
  plan: PlanState,
  selectedPlanFromJason: PlanFromJSON
) => {
  var totalByNutrition = plan.UseNutrition
    ? selectedPlanFromJason.nutrition.cost
    : 0;

  var totalByOdontology = plan.UseOdontology
    ? selectedPlanFromJason.odontology.cost
    : 0;

  var totalBillingFeature =
    plan.UseBilling === true ? selectedPlanFromJason.billing.cost : 0;

  var subTotal =
    plan.Pricing + totalBillingFeature + totalByNutrition + totalByOdontology;

  var TaxAmount = round(subTotal * plan.Tax, 2);

  var totalWithTax = subTotal + TaxAmount;

  return {
    ...plan,
    SubTotal: subTotal,
    TaxAmount: TaxAmount,
    TotalByNutrition: totalByNutrition,
    TotalByOdontology: totalByOdontology,
    TotalWithTax: totalWithTax,
    TotalByBilling: totalBillingFeature,
  };
};

const filterPlanByCountry = (
  countryCode: string,
  plansFromJSON: PlanFromJSON[],
  countries: Country[]
) => {
  if (countryCode === null) return plansFromJSON;

  var selectedCountry = countries.filter((x) => x.code === countryCode)[0];

  var newPlans = [];

  newPlans = plansFromJSON.filter(function (plan) {
    return selectedCountry.plans.includes(plan.uniqueId);
  });
  return newPlans;
};

const round = (value: any, precision: any) => {
  const multiplier = 10 ** (precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

const getIsLeadFormValid = (lead: LeadState) => {
  if (
    !lead.Email ||
    !lead.FirstName ||
    !lead.LastName ||
    !lead.Password ||
    !lead.PhoneNumber ||
    !lead.Specialty
  )
    return false;

  if (
    lead.Email === null ||
    lead.FirstName === null ||
    lead.LastName === null ||
    lead.Password === null ||
    lead.PhoneNumber === null ||
    lead.Specialty === null
  ) {
    return false;
  }

  if (
    lead.Email === "" ||
    lead.FirstName === "" ||
    lead.LastName === "" ||
    lead.Password === "" ||
    lead.PhoneNumber === 0 ||
    lead.Specialty === 0
  ) {
    return false;
  }

  return true;
};
