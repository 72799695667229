import React from 'react';
import { Layout } from 'antd';
import PageFooter from '../../Footer';
import './IFrameLayoutStyles.less';

const { Content, Footer } = Layout;

const IFrameLayout: React.FC = ({ children }) => {
  return (
    <Layout className='iframe-layout'>
      <Content>
        {children}
      </Content>
      <Footer>
        <PageFooter />
      </Footer>
    </Layout>
  );
}

export default IFrameLayout;
