import { Country } from "../../store/account/Types";
import httpClient from "../AxiosWrapper";

export const fetchCountries = async () => {
  try {
    const response = await httpClient.get<Country[]>("/countries.json?v=1");

    return response.data;
  } catch {
    return [];
  }
};
