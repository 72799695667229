import React from "react";
import { PoweredBy } from "../PoweredBy";

const PageFooter: React.FC = () => {
  return (
    <div className="footer">
      <PoweredBy></PoweredBy>
    </div>
  );
};

export default PageFooter;
