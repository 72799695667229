import React from "react";

const PageHeader: React.FC = () => {
  return (
    <div className="header">
      <img
        src="/blanco_500.png"
        width="93px"
        height="40px"
        alt="Siku"
        style={{ marginTop: 10, marginBottom: 10 }}
      />
    </div>
  );
};

export default PageHeader;
