import React from "react";
import "@ant-design/compatible/assets/index.css";
import { Row, Col } from "antd";
import { LeadInformation } from "../LeadInformation";
import { BillingInformation } from "../BillingInformation";
const PageStepTwo: React.FC = () => {
  return (
    <Row>
      <Col md={11} xs={24}>
        <LeadInformation></LeadInformation>
      </Col>
      <Col md={2}>
        <div className="divider"></div>
      </Col>
      <Col md={11} xs={24}>
        <BillingInformation></BillingInformation>
      </Col>
    </Row>
  );
};
export default PageStepTwo;
