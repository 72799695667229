import React, { useEffect } from "react";
import { I18nProvider } from "@lingui/react";
import { useQuery } from "../../utils";
import { i18n } from "../../utils";
import { useSelector, useDispatch } from "react-redux";
import { LocaleState, changeLocale } from "../../store/locale";

const LocaleProvider: React.FC = (props) => {
  const { children } = props;
  const localeState = useSelector(mapStateToProps);
  const dispatch = useDispatch();
  const query = useQuery();

  useEffect(() => {
    const newLocale = query.get("lang");

    dispatch(changeLocale(String(newLocale)));
    // eslint-disable-next-line
  }, []);

  return (
    <I18nProvider language={localeState.value} i18n={i18n}>
      {children}
    </I18nProvider>
  );
};

const mapStateToProps = (state: any): LocaleState => state.localeState;

export default LocaleProvider;
