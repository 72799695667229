import React, { useEffect } from "react";
import { Trans } from "@lingui/react";
import "@ant-design/compatible/assets/index.css";
import { Row, Col, Select, Button, Checkbox, Form, notification } from "antd";
import "./StepOne.less";
import { useSelector, useDispatch } from "react-redux";
import { SubscriptionSubTypeState } from "../../store/stepone";
import { SignupState, showStepTwo } from "../../store/signup";
import {
  AccountState,
  setSelectedPlan,
  setCountries,
  setSelectedCountry,
  filterPlanByCountry,
  setPlanList,
  setSelectedFeatures,
} from "../../store/account";
import { fetchPlans } from "../../services/SubscriptionService";
import { fetchCountries } from "../../services/CountryService";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useQuery } from "../../utils";
import { InfoCircleOutlined } from "@ant-design/icons";

interface Params {
  planName: string;
  planMode: string;
}

const PageStepOne: React.FC = () => {
  const query = useQuery();

  const { planName, planMode } = useParams<Params>();
  const countryFromUrl = String(query.get("country"));
  const { accountState } = useSelector(mapStateToProps);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const URL_PLAN_EHR = "ehr";
  const URL_PLAN_BILLING = "billing";
  const URL_PLAN_AGENDA_BILLING = "agenda-billing";
  const URL_PLAN_AGENDA = "agenda";
  const COSTA_RICA_CODE_IDENTIFIER = "CRI";
  const COLOMBIA_CODE_IDENTIFIER = "COL";
  const PLANS_AGENDA = 8;
  const PLANS_BILLING = 2;
  const PLANS_AGENDA_BILLING = 3;
  const PLANS_CRI_EHR = 4;
  const PLANS_INTERNATIONAL_EHR = 5;
  const PLANS_COL_AGENDA = 6;
  const PLANS_COL_EHR = 7;

  //use effects

  useEffect(() => {
    if (accountState.Countries.length === 0) {
      fetchCountries().then(function (response) {
        dispatch(setCountries(response));
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      accountState.Countries.length > 0 &&
      accountState.selectedCountryCode === ""
    ) {
      var defaultCountry = countryFromUrl;

      if (defaultCountry === "null")
        defaultCountry = COSTA_RICA_CODE_IDENTIFIER;

      dispatch(setSelectedCountry(defaultCountry));
      form.setFieldsValue({
        selectedCountryCode: defaultCountry,
      });
    }
  }, [accountState.Countries]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (accountState.selectedCountry) {
      fetchPlans().then(function (response) {
        dispatch(setPlanList(response));
      });
    }
  }, [accountState.selectedCountry]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (accountState.selectedCountry) {
      dispatch(filterPlanByCountry(accountState.selectedCountry.code));
    }
  }, [accountState.PlansFromJSON]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const withOdontology = String(query.get("odo")) === "true";
    const withNutrition = String(query.get("nutri")) === "true";
    const withBilling = String(query.get("billing")) === "true";

    if (accountState.FilteredPlansFromJSON.length > 0) {
      //PlanId is equal to 0 when first load, here is where default selected plan is set
      if (accountState.SelectedPlan.PlanId === 0) {
        var uniqueId = PLANS_AGENDA;

        switch (planName) {
          case URL_PLAN_AGENDA:
            if(accountState.selectedCountryCode === COLOMBIA_CODE_IDENTIFIER){
              uniqueId = PLANS_COL_AGENDA;
            }else{
              uniqueId = PLANS_AGENDA;
            }
            break;
          case URL_PLAN_BILLING:
            if(accountState.selectedCountryCode === COLOMBIA_CODE_IDENTIFIER){
            return  foo();
            }else {
              uniqueId = PLANS_BILLING;
            }
            break;
          case URL_PLAN_AGENDA_BILLING:
            uniqueId = PLANS_AGENDA_BILLING;
            break;
          case URL_PLAN_EHR:
            if (
              accountState.selectedCountryCode === COSTA_RICA_CODE_IDENTIFIER
            ) {
              uniqueId = PLANS_CRI_EHR;
            } else if (accountState.selectedCountryCode === COLOMBIA_CODE_IDENTIFIER) {
              uniqueId = PLANS_COL_EHR;
            } else uniqueId = PLANS_INTERNATIONAL_EHR;
            break;
        }

        dispatch(
          setSelectedPlan(
            uniqueId,
            planMode === "try",
            withNutrition,
            withOdontology,
            withBilling
          )
        );
        form.setFieldsValue({
          selectedPlan: uniqueId,
        });
      } else {
        var newid = accountState.FilteredPlansFromJSON[0].uniqueId;

        dispatch(
          setSelectedPlan(
            newid,
            planMode === "try",
            withNutrition,
            withOdontology,
            withBilling
          )
        );
        form.setFieldsValue({
          selectedPlan: newid,
        });
      }
    }
  }, [accountState.FilteredPlansFromJSON]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (accountState.SelectedPlan.PlanId > 0) {
      form.setFieldsValue({
        selectedCountryCode: accountState.selectedCountryCode,
        PlanId: accountState.SelectedPlan.PlanId,
        UseAgenda: accountState.SelectedPlan.UseAgenda,
        UseBilling: accountState.SelectedPlan.UseBilling,
        UseNutrition: accountState.SelectedPlan.UseNutrition,
        UseOdontology: accountState.SelectedPlan.UseOdontology,
      });
    }
  }, [accountState.SelectedPlan]); // eslint-disable-line react-hooks/exhaustive-deps

  //user events handlers
  const onCountryChange = (countryCode: any) => {
    dispatch(setSelectedCountry(countryCode));
  };

  const onPlanChange = (uniqueId: any) => {
    const withOdontology = String(query.get("odo")) === "true";
    const withNutrition = String(query.get("nutri")) === "true";
    const withBilling = String(query.get("billing")) === "true";

    dispatch(
      setSelectedPlan(
        uniqueId,
        planMode === "try",
        withNutrition,
        withOdontology,
        withBilling
      )
    );
  };

  const onContinue = () => {
    dispatch(showStepTwo());
  };

  //checkbox
  const onBillingCheckedChange = (e: any) => {
    form.setFieldsValue({
      UseBilling: e.target.checked,
    });
    updateSelectedFeaturesAndTotal();
  };

  const onNutritionCheckedChange = (e: any) => {
    form.setFieldsValue({
      UseNutrition: e.target.checked,
    });
    updateSelectedFeaturesAndTotal();
  };

  const onOdontologyCheckedChange = (e: any) => {
    form.setFieldsValue({
      UseOdontology: e.target.checked,
    });
    updateSelectedFeaturesAndTotal();
  };

  //calculate total and update state
  const updateSelectedFeaturesAndTotal = () => {
    var newValues = {
      UseAgenda: form.getFieldValue("UseAgenda"),
      UseBilling: form.getFieldValue("UseBilling"),
      UseNutrition: form.getFieldValue("UseNutrition"),
      UseOdontology: form.getFieldValue("UseOdontology"),
    };

    dispatch(setSelectedFeatures(newValues));
  };

  const foo = () => {
    const args = {
      message: 'Error',
      description:
        'El plan especificado no es válido, por favor seleccione un plan valido.',
      duration: 0,
    };
    notification.error(args);

   

  };

  return (
    <Row>
      <Col md={11} xs={24}>
        <div>
          <Form form={form} layout="vertical">
            <Row>
              <Col xs={24}>
                <Form.Item
                  name="selectedCountryCode"
                  label={<Trans id="LeadInformation.Country">Country</Trans>}
                >
                  <Select onChange={onCountryChange}>
                    {accountState.Countries.map((countries, index) => (
                      <Select.Option key={index} value={countries.code}>
                        {countries.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Form.Item
                  name="selectedPlan"
                  label={
                    <Trans render="span" id="StepOneComponent.PlanLabel">
                      Plan
                    </Trans>
                  }
                >
                  <Select onChange={onPlanChange}>
                    {accountState.FilteredPlansFromJSON.map((plan, index) => (
                      <Select.Option key={index} value={plan.uniqueId}>
                        {plan.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                {accountState.BasePlanFromJSON !== undefined && (
                  <div>
                    <h1>
                      <Trans render="span" id="StepOneComponent.Title2">
                        Features
                      </Trans>
                    </h1>
                    {accountState.BasePlanFromJSON.features.map(
                      (feature, index) => (
                        <div key={index}>
                          <span className="feature">{feature}</span>
                        </div>
                      )
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </Form>
        </div>
        <Row className="margin-botton-10">
          <Col>
            <Trans
              render="span"
              className="leyend"
              id="StepOneComponent.FeaturesLeyend"
            >
              Some restrictions may apply
            </Trans>
          </Col>
        </Row>
      </Col>
      <Col md={2}>
        <div className="divider"></div>
      </Col>
      <Col md={11} xs={24}>
        <div>
          <h2>
            <Trans render="span" id="StepOneComponent.Title3">
              Customize
            </Trans>
          </h2>
          <Form form={form}>
            {accountState.BasePlanFromJSON.ehr.isAllowed && (
              <Row>
                <Col md={12} xs={24}>
                  <Form.Item name="UseAgenda">
                    <Checkbox checked={true}>
                      <Trans render="span" id="StepOneComponent.EhrAvailable">
                        Ehr
                      </Trans>
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                  {accountState.BasePlanFromJSON.ehr.description}
                </Col>
              </Row>
            )}
            {accountState.BasePlanFromJSON.ehr.isAllowed && (
              <Row className="margin-botton-10">
                <Col>
                  <InfoCircleOutlined
                    style={{ fontSize: "16px" }}
                    twoToneColor="#eb2f96"
                  />
                  <Trans
                    render="span"
                    className="leyend"
                    id="StepOneComponent.ExtraHealthProvidersLeyend"
                  >
                    Extra health providers can be added later
                  </Trans>
                </Col>
              </Row>
            )}
            {accountState.BasePlanFromJSON.agenda.isAllowed && (
              <Row>
                <Col md={12} xs={24}>
                  <Form.Item name="UseAgenda">
                    <Checkbox checked={true}>
                      <Trans
                        render="span"
                        id="StepOneComponent.AgendaAvailable"
                      >
                        Agenda available
                      </Trans>
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                  {accountState.BasePlanFromJSON.agenda.description}
                </Col>
              </Row>
            )}
            {accountState.BasePlanFromJSON.billing.isAllowed && (
              <Row>
                <Col md={12} xs={24}>
                  <Form.Item name="UseBilling">
                    <Checkbox
                      checked={accountState.SelectedPlan.UseBilling}
                      onChange={onBillingCheckedChange}
                    >
                      <Trans
                        render="span"
                        id="StepOneComponent.BillingAvailable"
                      >
                        Billing available
                      </Trans>
                    </Checkbox>
                  </Form.Item>
                </Col>

                <Col md={12} xs={24}>
                  {accountState.BasePlanFromJSON.billing.description}
                </Col>
              </Row>
            )}
            {accountState.BasePlanFromJSON.nutrition.isAllowed && (
              <Row>
                <Col md={12} xs={24}>
                  <Form.Item name="UseNutrition">
                    <Checkbox
                      checked={accountState.SelectedPlan.UseNutrition}
                      onChange={onNutritionCheckedChange}
                    >
                      {
                        <Trans render="span" id="StepOneComponent.Nutrition">
                          Nutrition
                        </Trans>
                      }
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                  Mediciones, cálculo de dieta, menús y app para el paciente
                </Col>
              </Row>
            )}
            {accountState.BasePlanFromJSON.nutrition.isAllowed && (
              <Row>
                <Col md={12} xs={24}>
                  <Form.Item name="UseOdontology">
                    <Checkbox
                      checked={accountState.SelectedPlan.UseOdontology}
                      onChange={onOdontologyCheckedChange}
                    >
                      <Trans render="span" id="StepOneComponent.Odontology">
                        Odontology
                      </Trans>
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                  Odontograma, periodontograma y laboratorios
                </Col>
              </Row>
            )}
            <Row className="pricing-breakdown">
              <Col xs={16} md={16}>
                <h2>{accountState.BasePlanFromJSON.name}</h2>
              </Col>
              <Col xs={8} md={8}>
                <h3 className="info-box-pricing">
                  {accountState.selectedCountry?.currency}{" "}
                  {accountState.BasePlanFromJSON.pricing}
                </h3>
              </Col>
            </Row>
            {accountState.SelectedPlan.TotalByBilling > 0 && (
              <Row>
                <Col xs={16} md={16}>
                  <h3>Facturación</h3>
                </Col>
                <Col xs={8} md={8}>
                  <h3 className="info-box-pricing">
                    {accountState.selectedCountry?.currency}{" "}
                    {accountState.SelectedPlan.TotalByBilling}
                  </h3>
                </Col>
              </Row>
            )}
            {accountState.SelectedPlan.TotalByNutrition > 0 && (
              <Row>
                <Col xs={16} md={16}>
                  <h3>Nutrición</h3>
                </Col>
                <Col xs={8} md={8}>
                  <h3 className="info-box-pricing">
                    {accountState.selectedCountry?.currency}{" "}
                    {accountState.SelectedPlan.TotalByNutrition}
                  </h3>
                </Col>
              </Row>
            )}
            {accountState.SelectedPlan.TotalByOdontology > 0 && (
              <Row>
                <Col xs={16} md={16}>
                  <h3>Odontología</h3>
                </Col>
                <Col xs={8} md={8}>
                  <h3 className="info-box-pricing">
                    {accountState.selectedCountry?.currency}{" "}
                    {accountState.SelectedPlan.TotalByOdontology}
                  </h3>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={16} md={16}>
                <h2>Subtotal</h2>
              </Col>
              <Col xs={8} md={8}>
                <h3 className="info-box-pricing">
                  {accountState.selectedCountry?.currency}{" "}
                  {accountState.SelectedPlan.SubTotal}
                </h3>
              </Col>
            </Row>
            {accountState.SelectedPlan.TaxAmount > 0 && (
              <Row>
                <Col xs={16} md={16}>
                  <h2>Impuesto</h2>
                </Col>
                <Col xs={8} md={8}>
                  <h3 className="info-box-pricing">
                    {accountState.selectedCountry?.currency}{" "}
                    {accountState.SelectedPlan.TaxAmount}
                  </h3>
                </Col>
              </Row>
            )}
            <Row className="pricing-breakdown">
              <Col xs={16} md={16}>
                <h2 className="pricing-total">Total</h2>
              </Col>
              <Col xs={8} md={8}>
                <h2 className="info-box-pricing pricing-total">
                  {accountState.selectedCountry?.currency}{" "}
                  {accountState.SelectedPlan.TotalWithTax}
                </h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <span>{accountState.selectedCountry?.currencyCaption}</span>
              </Col>
            </Row>
            <Row className="pricing-breakdown">
              <Col xs={24}>
                <Button type="primary" onClick={onContinue} block>
                  <Trans id="StepOne.ContinueButton">Continue</Trans>
                </Button>
              </Col>
            </Row>
            {accountState.SelectedPlan.IsTry === true && (
              <Row className="total-box-leyend">
                <Col xs={16} md={16}>
                  <Trans render="strong" id="StepOneComponent.TodayTotalLeyend">
                    Today's charge
                  </Trans>
                </Col>
                <Col xs={8} md={8} className="info-box-pricing">
                  <h3 className="info-box-pricing">
                    {accountState.selectedCountry?.currency} 0
                  </h3>
                </Col>
                <Col md={24}>
                  <Trans render="div" id="StepOneComponent.TodayTotalSubLeyend">
                    While in trial your card will not be charged
                  </Trans>
                </Col>
              </Row>
            )}
            {accountState.SelectedPlan.IsTry === true && (
              <Row className="total-box-leyend">
                <Col xs={16} md={16}>
                  <Trans
                    render="strong"
                    id="StepOneComponent.AfterTrialTotalLeyend"
                  >
                    Total after trial
                  </Trans>
                </Col>
                <Col xs={8} md={8} className="info-box-pricing">
                  <h3 className="info-box-pricing">
                    {accountState.selectedCountry?.currency}{" "}
                    {accountState.SelectedPlan.TotalWithTax}
                  </h3>
                </Col>
                <Col md={24}>
                  <Trans
                    render="div"
                    id="StepOneComponent.AfterTrialTotalSubLeyend"
                  >
                    You can cancel anytime. You wont be charged until your free
                    trial ends on
                  </Trans>
                  {moment().add(7, "d").format("DD MMMM YYYY")}
                </Col>
              </Row>
            )}
            {accountState.SelectedPlan.IsTry === false && (
              <Row className="total-box-leyend">
                <Col xs={16} md={16}>
                  <Trans render="strong" id="StepOneComponent.TodayTotalLeyend">
                    Today's charge
                  </Trans>
                </Col>
                <Col xs={8} md={8} className="info-box-pricing">
                  <h3 className="info-box-pricing">
                    {accountState.selectedCountry?.currency}{" "}
                    {accountState.SelectedPlan.TotalWithTax}
                  </h3>
                </Col>
                <Col md={24}>
                  <Trans
                    render="div"
                    id="StepOneComponent.TodayTotalBuySubLeyend"
                  >
                    You can cancel anytime.
                  </Trans>
                </Col>
              </Row>
            )}
          </Form>
        </div>
      </Col>
    </Row>
  );
};
export default PageStepOne;

const mapStateToProps = (state: any): State => ({
  signupState: state.signupState,
  subscriptionSubTypeState: state.subscriptionSubTypeState,
  accountState: state.accountState,
});

interface State {
  signupState: SignupState;
  subscriptionSubTypeState: SubscriptionSubTypeState;
  accountState: AccountState;
}
