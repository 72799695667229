import React from "react";
import { Layout } from "antd";
import { StepOne as PageStepOne } from "../../components/StepOne";
import { useSelector } from "react-redux";
import { SignupState } from "../../store/signup";
import PageStepTwo from "../../components/StepTwo/StepTwo";
import { useParams } from "react-router-dom";
import PageStepThree from "../../components/StepThree/StepThree";
import { logAmplitudeEvent } from "../../utils/amplitude";

interface Params {
  planMode: string;
}

const Signup: React.FC = () => {
  const { planMode } = useParams<Params>();
  const { Content } = Layout;
  const signupState = useSelector(mapStateToProps);

  const renderStep = () => {
    if (signupState.showOne) return <PageStepOne />;
    if (signupState.showTwo) return <PageStepTwo />;
    if (signupState.showThree) return <PageStepThree />;
  };

  if (signupState.showOne) logAmplitudeEvent("NAV_SIGNUP_STEP_ONE");
  if (signupState.showTwo) logAmplitudeEvent("NAV_SIGNUP_STEP_TWO");

  return (
    <React.Fragment>
      <Content className="site-layout">
        {planMode === "try" && (
          <h1 className="title">Prueba gratuita de Siku</h1>
        )}
        {planMode === "buy" && <h1 className="title">Registro en Siku</h1>}
        <div className="site-layout-content" style={{ background: "#fff" }}>
          {renderStep()}
        </div>
      </Content>
    </React.Fragment>
  );
};

export default Signup;
const mapStateToProps = (state: any): SignupState => state.signupState;
