import React from 'react';
import { Route, RouteProps } from 'react-router';
import { StandardLayout } from '../Layouts';

interface Props extends RouteProps {
  component: React.FC<RouteProps>
}

const StandardLayoutRoute: React.FC<Props> = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={matchProps => (
      <StandardLayout>
        <Component {...matchProps} />
      </StandardLayout>
    )} />
  )
};

export default StandardLayoutRoute;  