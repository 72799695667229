import { createStore, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { signUpReducer } from "./signup/Reducer";
import { stepOneReducer } from "./stepone/Reducer";
import { localeReducer } from "./locale/Reducer";
import { accountReducer } from "./account/Reducer";
import { freemiumReducer } from "./Freemium/Reducer";

const AppStore = createStore(
  combineReducers({
    signupState: signUpReducer,
    subscriptionSubTypeState: stepOneReducer,
    localeState: localeReducer,
    accountState: accountReducer,
    freemiumState: freemiumReducer,
  }),
  process.env.NODE_ENV === "development" ? composeWithDevTools() : undefined
);
export default AppStore;
