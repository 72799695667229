import React from "react";
import { Row, Result } from "antd";
import { Trans } from "@lingui/react";
import LocaleProvider from "../components/utils/LocaleProvider";

const NotFound: React.FC = () => {
  return (
    <LocaleProvider>
      <Row
        justify="center"
        align="middle"
        className="viewport-height"
      >
        <Result
          status="404"
          title={<Trans id="PageNotFound.Title">Page not found</Trans>}
          subTitle={
            <Trans id="PageNotFound.Subtitle">
              Sorry, the page you are trying to access does not exists or ir not
              available at the moment.
            </Trans>
          }
        />
      </Row>
    </LocaleProvider>
  );
};

export default NotFound;
