export const SHOW_STEP_ONE = "SHOW_STEP_ONE";
export const SHOW_STEP_TWO = "SHOW_STEP_TWO";
export const SHOW_STEP_THREE = "SHOW_STEP_THREE";
export const SET_INFO_STEP_ONE = "SET_INFO_STEP_ONE";
export const SET_INFO_STEP_TWO = "SET_INFO_STEP_TWO";

export const showStepOne = () => ({
  type: SHOW_STEP_ONE,
});

export const showStepTwo = () => ({
  type: SHOW_STEP_TWO,
});

export const showStepThree = () => ({
  type: SHOW_STEP_THREE,
});

export const setInfoStepOne = () => ({
  type: SET_INFO_STEP_ONE,
});

export const setInfoStepTwo = () => ({
  type: SET_INFO_STEP_TWO,
});
