import { LocaleState } from "./Types";
import { CHANGE_LOCALE } from "./Actions";
import { locales } from "../../config";
import { i18n } from "../../utils";
import moment from "moment";

i18n.load(locales.catalogs);

const changeLocale = (locale: LocaleState) => {
  moment.locale(locale.value);
  i18n.activate(locale.value);
};

const findLocale = (locale: string) =>
  locales.locales.find((x) => x.value === locale) || locales.locales[0];

const getLocale = (): LocaleState => {
  let locale: LocaleState;

  locale = locales.locales[0];

  changeLocale(locale);

  return locale;
};

const initialState: LocaleState = getLocale();

export const localeReducer = (
  state = initialState,
  action: any
): LocaleState => {
  switch (action.type) {
    case CHANGE_LOCALE:
      const newLocale = findLocale(action.locale);
      changeLocale(newLocale);
      return newLocale;

    default:
      return state;
  }
};
