import { FreemiumState } from "./Types";
import {
  SET_COUNTRIES,
  SET_PLAN_FEATURES,
  SET_SELECTED_COUNTRY,
  SET_PROCESSING_REQUEST,
  HIDE_FREEMIUM_COMPONENT,
  SET_NEWFREEMIUMACOUNT_RESPONSE,
} from "./Actions";

const initialState: FreemiumState = {
  subscriptionSubTypes: [],
  filteredPlans: [],
  Countries: [],
  PlanFeaturesFromJSON: [],
  selectedCountryCode: "",
  selectedCountry: undefined,
  isProcessing: false,
  showSuccessMessage: false,
  newFreemiumAccountResponse: null,
};

export const freemiumReducer = (
  state = initialState,
  action: any
): FreemiumState => {
  switch (action.type) {
    case SET_COUNTRIES:
      return {
        ...state,
        Countries: action.countries.sort(),
      };
    case SET_PLAN_FEATURES:
      return {
        ...state,
        PlanFeaturesFromJSON: action.features,
      };
    case SET_SELECTED_COUNTRY:
      var selected = state.Countries.filter(
        (x) => x.code === action.countryCode
      )[0];

      return {
        ...state,
        selectedCountryCode: action.countryCode,
        selectedCountry: selected,
      };
    case SET_PROCESSING_REQUEST:
      return {
        ...state,
        isProcessing: action.isProcessing,
      };
    case HIDE_FREEMIUM_COMPONENT:
      return {
        ...state,
        showSuccessMessage: true,
      };
    case SET_NEWFREEMIUMACOUNT_RESPONSE:
      return {
        ...state,
        newFreemiumAccountResponse: action.response,
      };
    default:
      return state;
  }
};
